@import "../../style/typography.scss";
@import "../../style/colors.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  height: auto;
  background: rgb(255 255 255 / 99%);
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 11px 15px -7px rgb(204 179 179 / 93%);
  border-radius: 4px;
}

.content {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  overflow-y: auto;
}

.darkMode {
  background-color: rgb(59 59 59 / 96%);
  color: white;
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    1px 5px 13px 0px rgb(106 106 106 / 93%);

  .closeIcon {
    color: white;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

::-webkit-scrollbar-thumb:hover {
  background: #6e6e6e;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px;
  align-items: center;
  border-bottom: 1px solid $GREY_20;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.closeIcon {
  color: black;
  width: 18px;
  height: 18px;
}

.footer {
  display: flex;
  column-gap: 8px;
  justify-content: flex-end;
  padding: 16px 24px 24px;
  border-top: 1px solid $GREY_20;
}
