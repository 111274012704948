@import "../../style/typography.scss";
@import "../../style/colors.scss";

a.creditsLink {
  text-decoration: none;
  font-family: $font;
  color: black;

  &:hover {
    color: $link-hover;
  }
}

.creditsTitle {
  font-family: $font;
}
