@import "./typography.scss";
@import "./colors.scss";

.container {
  width: -webkit-fill-available;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 1em;
  background-color: $background-color-normal;
}

.containerDark {
  color: #fff;
  background-color: $background-color-dark;

  a.link {
    color: #fff;
  }
}

.content {
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 30px;
  column-gap: 1em;
}

.text {
  font-family: $font;
  font-size: 14px;
  text-align: left;
  letter-spacing: 1px;
}

.textbreak {
  padding: 5px;
}

a.link {
  text-decoration: none;
  color: black;
  font-weight: bold;
  &:hover {
    color: rgb(156, 39, 176);
  }
}

.title {
  font-weight: 500;
  transition: transform 750ms;
  will-change: transform;
  &:hover {
    transform: translateY(10px);
    color: $link-hover;
  }
}

.section1 {
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
}
.subsection {
  display: flex;
  flex-direction: column;
  column-gap: 1.5em;
  row-gap: 1em;
}

.profileImage {
  border-radius: 300px;
  width: 400px;
}

.profileImageContainer {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1200px) and (min-width: 0px) {
  .profileImage {
    width: 80%;
  }

  .content {
    display: grid;
    grid-template-columns: auto;
    row-gap: 1em;
  }
}
