@import "../../style/typography.scss";
@import "../../style/colors.scss";

.container {
  display: flex;
  align-items: flex-start;
  row-gap: 1em;
  flex-direction: column;
  font-family: $font;
  font-size: 14px;
}

.content {
  padding: 10px;
  border: 0.5px solid rgb(207, 207, 207);
  background-color: rgba(243, 243, 243, 0.7215686275);
  display: flex;
  column-gap: 1.5em;
  row-gap: 1.5em;
  flex-wrap: wrap;
  border-radius: 15px;
  transition: transform 750ms;
  will-change: transform;
  &:hover {
    transform: translateY(10px);
  }
}

h1.title {
  font-weight: 500;
  transition: transform 750ms;
  will-change: transform;

  &:hover {
    color: $link-hover;
    transform: translateY(10px);
  }
}

.text {
  width: 100%;
  font-family: $font;
  letter-spacing: 1px;
  font-weight: 600;
}

.containerDark {
  .title {
    color: #fff;
  }
  .text {
    color: black;
  }
  .content {
    background-color: #f8f7f7b8;
  }
  .skills {
    &:hover {
      background-color: rgba(255, 249, 249, 0.259);
      border-radius: 10px;
    }
  }
}

.skills {
  display: flex;
  align-items: center;
  column-gap: 0.5em;
  padding: 5px 5px;

  &:hover {
    background-color: rgba(169, 169, 169, 0.259);
    border-radius: 10px;
    transform: scale(1.02) perspective(0px);
    box-shadow: 0 10px 10px rgba(199, 199, 199, 0.549);
  }
}

i {
  font-size: 1.5rem;
}

.header {
  display: flex;
  justify-content: flex-start;
}
