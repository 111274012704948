@import "../../style/typography.scss";
@import "../../style/colors.scss";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffcddb;
  width: 100%;
  border-radius: 10px;
  font-family: $font;
  font-weight: 500;
  transition: transform 750ms;
  will-change: transform;

  &:hover {
    transform: translateY(10px);
  }
}

.title {
  margin-left: 10px;
  padding: 10px;
}

.showIcon,
.hideIcon {
  color: $link-hover;
}

.text {
  margin: 0px;
}

.container {
  width: 100%;
  font-family: $font;
  display: flex;
  text-align: left;
  flex-direction: column;
  row-gap: 1em;
}

.containerOpen {
  .header {
    background-color: #fb96b2;
  }
}

.subsectionContent {
  display: flex;
  flex-direction: column;
}

.containerDark {
  .subsection {
    color: black;
  }
  .showIcon,
  .hideIcon {
    color: #fff;
  }

  .header {
    color: black;
    background-color: #c0bdbd;
  }
  .locationIcon {
    color: #979595;
  }
}

.locationIcon {
  color: $link-hover;
}

.headercta {
  display: flex;
  flex-direction: row;
  column-gap: 2em;
  align-items: center;
  padding: 10px;
}

.content {
  display: flex;
  padding: 10px;
  background-color: #fff;
  flex-direction: column;
  border-radius: 10px;
}

.logoContainer {
  width: 150px;
  padding: 12px;
  margin: 0;
  img {
    width: 100%;
  }
}

.subsection {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: flex-start;
}

.contentHeader {
  display: flex;
  column-gap: 4em;
  padding: 5px;
  align-items: center;
}

.contextText {
  font-size: 14px;
  margin-left: 1em;
}

a.link,
.location {
  display: flex;
  text-decoration: none;
  align-items: center;
  column-gap: 0.5em;
  color: black;
}

a.link {
  &:hover {
    color: rgb(156, 39, 176);
  }
}
.skills {
  display: flex;
  row-gap: 0.5em;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.5em;
  padding: 5px 5px;
}

.skill {
  padding: 10px;
  border: 0.5px solid rgb(207, 207, 207);
  background-color: rgba(243, 243, 243, 0.7215686275);
  display: flex;
  column-gap: 1.5em;
  font-size: 16px;
  font-weight: 500px;
  row-gap: 1.5em;
  flex-wrap: wrap;
  border-radius: 15px;

  &:hover {
    background-color: rgba(169, 169, 169, 0.259);
    border-radius: 10px;
    transform: scale(1.02) perspective(0px);
    box-shadow: 0 10px 10px rgba(199, 199, 199, 0.549);
  }
}

@media only screen and (max-width: 500px) {
  .title {
    white-space: break-spaces;
    margin: 0px;
    text-align: center;
  }
  .header {
    flex-direction: column;
    width: auto;
  }
  .headercta {
    display: flex;
    align-items: center;
    width: auto;
    flex-direction: row;
    column-gap: normal;
  }

  .contentHeader {
    column-gap: 2em;
    flex-direction: column;
    row-gap: 1em;
  }

  .subsection {
    display: block;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

@media only screen and (device-width: 600px) {
  /* For general iPad layouts */
  .title {
    white-space: break-spaces;
    margin: 0px;
    text-align: center;
  }
  .header {
    width: auto;
  }

  .headercta {
    display: flex;
    align-items: center;
    width: auto;
    flex-direction: row;
    column-gap: normal;
  }
  .container {
    width: auto;
  }
  .text {
    margin: 0px;
  }
}
