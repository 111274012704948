@import "../../style/colors.scss";

.successMessageContainer {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: flex-start;
  row-gap: 10px;
  padding: 12px;
}

.messagebubble,
.messagebubble2 {
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  padding: 12px 14px;
  position: relative;
  width: fit-content;
}

.dateString {
  display: none;
  visibility: hidden;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.showDateString {
  animation: fadein 3s;
  display: flex;
  visibility: visible;
  transition: opa;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.showMessageBubble1,
.showMessageBubble2 {
  background-color: #1588ff;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  animation: slideUp 0.3s ease-in-out forwards;
  opacity: 1;
  visibility: visible;
  position: relative;
  box-shadow: rgba(126, 151, 179, 0.7) 0px 0px 3px;
  border-radius: 0px 50px 50px 50px;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
