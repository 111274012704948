@import "./typography.scss";
@import "./colors.scss";

.container {
  display: flex;
  flex-direction: column;
  min-height: 81vh;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  width: 100%;
  row-gap: 2em;
  font-family: $font;
  letter-spacing: 1px;
  background-color: $background-color-normal;
}

.wrapper {
  padding: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 2em;
}

.containerDark {
  background-color: $background-color-dark;
  color: #fff;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.modalContent {
  width: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 600px;
  font-family: $font;
}

.successContent {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  height: 275px;
  padding: 20px;
  align-items: center;
  column-gap: 2em;
}

.chatContainer {
  display: flex;
  border: 1px solid black;
  height: 250px;
  width: 50%;
  padding: 12px;
  align-items: center;
  background: #ededed;
  border-radius: 5px;
}

.≈ {
  width: 50%;
  padding: 16px;
  font-family: $font;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.socials {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.socialRow {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 10px;
  width: 100%;
  justify-content: start;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  padding: 4px;
}

.socialIcon {
  color: black;
  &:hover {
    color: $link-hover;
  }
}

.socialIconDarkMode {
  color: white;
  &:hover {
    color: $link-hover;
  }
}

.profileImage {
  border-radius: 300px;
  width: 350px;
}

.profileImageContainer,
.content,
.actions {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  flex-direction: column;
  align-items: center;
}

.requestCVButton {
  background-color: $background-color-dark !important;
  font-family: $font !important;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  padding: 12px;
  transition: transform 750ms;
  will-change: transform;
  &:hover {
    background-color: #c25d79;
    transform: translateY(10px);
  }
}

.requestCVButtonDark {
  border: 1px solid !important;
  color: #fff;

  &:hover {
    border: 1px solid #c25d79 !important;
  }
  &:focus {
    background-color: #c25d79 !important;
  }
}

.followMeCard {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  width: 80%;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 1200px) and (min-width: 0px) {
  .profileImage {
    width: 80%;
  }

  h1.title {
    text-align: center;
    font-size: 1.5em;
  }

  .successContent {
    flex-direction: column;
    height: auto;
  }
}
