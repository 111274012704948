@import "../../style/typography.scss";
@import "../../style/colors.scss";

.content {
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  font-family: $font;
  align-items: center;
}

.section {
  display: flex;
  column-gap: 1em;
}

input,
label {
  font-family: $font !important;
}

.sectionDark,
.midsectionDark {
  input {
    background: lightgrey;
    border-radius: 4px;
  }
}

.midsection,
.section,
.subsection {
  width: 100%;
}

.container {
  width: 100%;
  font-family: $font;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

textarea.message {
  width: 100%;
  border-top-left-radius: 4px;
  font-family: $font;
  border-top-right-radius: 4px;
}

button.sendButton {
  background-color: black;
  display: flex;
  column-gap: 1em;
  font-family: $font;
  width: 200px;
  border-radius: 20px;
  padding: 16px;
  transition: transform 750ms;
  will-change: transform;
  &:hover {
    background-color: #c25d79;
    transform: translateY(10px);
  }

  :disabled {
    background-color: rgb(0 0 0 / 22%) !important;
  }
}

.darkModeButton {
  background-color: #d78da2 !important;
  &:hover {
    background-color: #c25d79 !important;
    transform: translateY(10px);
  }
}

.darkModeButton:disabled {
  background-color: rgba(135, 133, 133, 0.731) !important;
}

form.form {
  justify-content: center;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  width: 100%;
  align-items: center;
}

.success {
  display: flex;
  width: 100%;
}

.formFields {
  width: 100%;
  row-gap: 1em;
  flex-direction: column;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .midsection,
  .section,
  .subsection {
    width: auto;
  }

  .section {
    flex-direction: column;
    row-gap: 1em;
  }
}
