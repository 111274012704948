@import "../../style/typography.scss";
@import "../../style/colors.scss";

.container {
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  z-index: 1;
  background-color: $white;
}

nav {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  color: #1c1111;
  font-family: $font2;
  padding: 15px;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-align: left;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  a {
    color: #1c1111;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
    text-decoration-color: $link-hover;
    text-decoration-thickness: 0.2em;
    text-underline-position: under;
    text-underline-offset: 10px;
    transform: rotate(2deg);
    transition: all 0.15s ease-in-out;
  }

  a.active {
    color: $link;
    text-decoration: underline;
    text-decoration-thickness: 0.2em;
    text-decoration-color: $link-hover;
    text-underline-position: under;
    text-underline-offset: 10px;
  }
}

.activeWeblink {
  color: $link !important;
}

a.name {
  text-decoration: none;
  color: black;
  margin: 0;
  white-space: nowrap;
  &:hover {
    color: $link-hover;
  }
  font-family: $font2;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2em;
}

.header {
  margin-left: 6em;
}

.containerDark {
  background-color: $background-color-dark;
  a {
    display: flex;
    justify-content: space-evenly;
    color: #fff;
  }

  .name {
    color: #fff;
    &:hover {
      color: $link-hover;
    }
  }

  .menuIcon {
    font-size: 1em;
    color: #fff;
  }

  .lightModeMobile,
  .darkModeMobile {
    color: #fff;
  }

  .linkContainer,
  .mobileLink {
    color: #fff;
  }

  nav a.mobileLink:hover {
    color: $link-hover;
  }
}

.mobile {
  display: none;
}

.toggleDarkMobile,
.toggleMobile {
  display: none;
}

.links {
  display: none;
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.lightModeMobile,
.darkModeMobile {
  color: black;
}

@media only screen and (max-width: 1200px) and (min-width: 0px) {
  .desktop {
    display: none;
  }

  .links {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  nav.linkContainer {
    width: fit-content;
    font-size: 1em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  a.mobileLink {
    color: #1c1111;
    text-decoration: none;
    font-family: $font2;
    font-size: 1em;
  }

  nav a:hover {
    text-decoration: none;
    transform: none;
    color: $link-hover;
  }

  .mobile {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 15px;
    justify-content: space-evenly;
    column-gap: 1em;
    width: fit-content;
  }

  a.name {
    font-size: 1em;
  }

  .header {
    margin-left: 3em;
  }

  .toggleDarkMobile,
  .toggleMobile {
    display: block;
    width: fit-content;
    height: fit-content;
  }
  .container {
    max-width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: space-between;
    position: sticky;
    z-index: 1;
  }

  .menuIcon {
    color: black;
  }

  .content {
    padding: 0px;
  }
}
