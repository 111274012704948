@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

@import "./colors.scss";

$font2: "Fredoka One", cursive;
$font: "Poppins", sans-serif;
$spacing: 8px;

h1 {
  font-size: 2em;
  line-height: 45px;
  margin-bottom: $spacing;
  font-family: $font;
  font-weight: 400;
}
h2 {
  font-size: 26px;
  line-height: 39px;
  margin-bottom: $spacing;
  font-family: $font;
}

h3 {
  font-size: 22px;
  line-height: 33px;
  margin-bottom: $spacing;
  font-family: $font;
}

h4 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.2px;
  font-family: $font;
  margin-bottom: $spacing;
}

h5 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: $spacing;
  font-family: $font;
  font-weight: normal;
}

h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 8px;
  font-family: $font;
}

a {
  color: $link;
  font-weight: 500;

  &:hover {
    color: $link-hover;
  }
}
