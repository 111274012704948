@import "../../style/typography.scss";
@import "../../style/colors.scss";

.container {
  display: block;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fill-available;
  width: 100%;
  align-items: center;
  position: sticky;
  z-index: 1;
  column-gap: 5em;
  text-align: center;
  background-color: $white;
}
.containerDark {
  background-color: $background-color-dark;
  color: #fff;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 2em;
  padding: 15px;
}

.socialIcon {
  color: black;
  &:hover {
    color: $link-hover;
  }

  svg:hover {
    fill: $link-hover;
  }
}

.tiktokIcon {
  &:hover {
    fill: $link-hover;
  }
}

.socialIconDarkMode {
  color: white;
  &:hover {
    color: $link-hover;
  }
}
.textContainer {
  display: flex;
  column-gap: 1.5em;
}
.text,
.credits {
  font-family: $font;
  font-size: 13px;
  white-space: nowrap;
  margin: 0;
  cursor: pointer;
}

.credits {
  &:hover {
    color: $link-hover;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 0px) {
  .socialIcon {
    font-size: 2rem;
  }

  .content {
    column-gap: 1em;
  }

  .text,
  .credits {
    font-size: 0.7em;
  }
  .wrapper {
    flex-direction: column-reverse;
    width: 100%;
  }

  .container {
    display: flex;
    justify-content: center;
  }
}
