//normal mode

$white: #fff;
$text: black;
$link: #ff5d96;
$link-hover: #c25d79;
$hover: #a0143b;
$background-color-normal: #ffdfe5;
$background-color-dark: #000000;
$GREY_20: #e0e0e0;
$GREY_40: #757575;
