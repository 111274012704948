@import "../../style/typography.scss";
@import "../../style/colors.scss";

a.coffeeButton {
  flex-direction: row;
  column-gap: 1em;
  background-color: #ffdfe5;
  color: black;
  font-family: $font;
  display: flex;
  width: fit-content;
  border-radius: 20px;
  transition: transform 750ms;
  will-change: transform;
  &:hover {
    background-color: #c25d79;
    color: #fff;
  }
}

.coffeeIcon {
  width: 20px;
}

.text {
  margin: 0;
}

@media only screen and (max-width: 1200px) and (min-width: 0px) {
  .text {
    display: none;
  }

  .coffeeIcon {
    width: 18px;
  }
}
