@import "../style/typography.scss";
@import "../style/colors.scss";

.container {
  min-height: 100vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
}

.darkContainer {
  background-color: black;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  width: 100%;
  height: 100vh;
  animation: backgroundChange 0.5s linear 3s forwards;
}

.loadText {
  left: 50%;
  top: 50%;
  font-family: $font2;
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 10vmax;
  width: fit-content;
  display: flex;
  animation: colorChange 0.5s linear 3s forwards;
}

.loadedText {
  transform: scale(1.5);
  font-family: $font2;
  animation: scaleText 0.5s linear 0.5s forwards;
}

.loadingText {
  width: 0;
  overflow: hidden;
  animation: expand 0.5s linear 1.5s forwards;
}

main {
  padding: 1em;
  box-sizing: border-box;
  background-color: black;
  color: white;
  min-height: 100vh;
  font-family: $font2;
}

::selection {
  color: white;
  background: rgb(11, 11, 11);
}

@keyframes scaleText {
  from {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes expand {
  from {
    width: 0;
  }
  to {
    width: 30vmax;
  }
}

@keyframes backgroundChange {
  from {
    background-color: white;
  }
  to {
    background-color: $background-color-dark;
  }
}

@keyframes colorChange {
  from {
    color: black;
  }
  to {
    color: white;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: -1;
  }
}
